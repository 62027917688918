let devConfig = {
  // configUrl: "http://localhost:3000",
  configUrl: "https://dev-app.qrata.ai",
  calenderMeetingUrl:
    "https://calendly.com/monishkulkarni/30-mins-meeting?back=1&month=2024-01",
  clientAnalyticsUrl: "https://dev-hireapi.qrata.ai/client",
};

let prodConfig = {
  configUrl: "https://app.qrata.ai",
  clientAnalyticsUrl: "https://hireapi.qrata.ai/clientprod",
  calenderMeetingUrl:
    "https://calendly.com/monishkulkarni/30-mins-meeting?back=1&month=2024-01",
};

let stagingConfig = {
  configUrl: "https://staging.app.qrata.ai",
  clientAnalyticsUrl: "https://staging.app.qrata.ai/client",
  calenderMeetingUrl:
    "https://calendly.com/monishkulkarni/30-mins-meeting?back=1&month=2024-01",
};
export const config = devConfig;
